/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-condensed/400.css'
import '@fontsource/roboto-condensed/700.css'
import '@fontsource/didact-gothic'
import './src/styles/global.css'
import grommetProvider from './grommet-provider'
export { wrapPageElement } from './gatsby-shared'

// wrapRootElement method will ensure that the context providers are available during build.
export const wrapRootElement = grommetProvider
