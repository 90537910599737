import tokens from '@dv01-inc/waterfall-design-tokens'
import { Grommet } from 'grommet'
import React from 'react'
import { css } from 'styled-components'

const grommetProvider = ({ element }) => {
  return (
    <Grommet
      plain
      theme={{
        global: {
          breakpoints: {
            small: {
              value: tokens.size.mediaquery.small.value,
            },
            medium: {
              value: tokens.size.mediaquery.medium.value,
            },
            large: {
              value: tokens.size.mediaquery.large.value,
            },
            xlarge: {
              value: tokens.size.mediaquery.xl.value,
            },
          },
          colors: {
            active: {
              dark: tokens.color.font.base.value,
              light: tokens.color.font.base.value,
            },
            black: tokens.color.base.gray[1000].value,
            border: {
              light: tokens.color.border.inverse.value,
              dark: tokens.color.border.base.value,
            },
            brand: {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.base.value,
            },
            primary: {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.base.value,
            },
            'primary-light': {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.light.value,
            },
            'primary-dark': {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.dark.value,
            },
            secondary: {
              light: tokens.color.brand.secondary.dark.value,
              dark: tokens.color.brand.secondary.base.value,
            },
            'secondary-light': {
              light: tokens.color.brand.secondary.dark.value,
              dark: tokens.color.brand.secondary.light.value,
            },
            'secondary-dark': {
              light: tokens.color.brand.secondary.dark.value,
              dark: tokens.color.brand.secondary.dark.value,
            },
            default: {
              light: tokens.color.brand.default.dark.value,
              dark: tokens.color.brand.default.base.value,
            },
            'default-light': {
              light: tokens.color.brand.default.dark.value,
              dark: tokens.color.brand.default.light.value,
            },
            'default-dark': {
              light: tokens.color.brand.default.dark.value,
              dark: tokens.color.brand.default.dark.value,
            },
            alternative: {
              light: tokens.color.brand.alternative.dark.value,
              dark: tokens.color.brand.alternative.base.value,
            },
            'alternative-light': {
              light: tokens.color.brand.alternative.dark.value,
              dark: tokens.color.brand.alternative.light.value,
            },
            'alternative-dark': {
              light: tokens.color.brand.alternative.dark.value,
              dark: tokens.color.brand.alternative.dark.value,
            },
            outline: {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.base.value,
            },
            'outline-light': {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.light.value,
            },
            'outline-dark': {
              light: tokens.color.brand.primary.dark.value,
              dark: tokens.color.brand.primary.dark.value,
            },
            success: {
              light: tokens.color.brand.success.dark.value,
              dark: tokens.color.brand.success.base.value,
            },
            'success-light': {
              light: tokens.color.brand.success.dark.value,
              dark: tokens.color.brand.success.light.value,
            },
            'success-dark': {
              light: tokens.color.brand.success.dark.value,
              dark: tokens.color.brand.success.dark.value,
            },
            danger: {
              light: tokens.color.brand.danger.dark.value,
              dark: tokens.color.brand.danger.base.value,
            },
            'danger-light': {
              light: tokens.color.brand.danger.dark.value,
              dark: tokens.color.brand.danger.light.value,
            },
            'danger-dark': {
              light: tokens.color.brand.danger.dark.value,
              dark: tokens.color.brand.danger.dark.value,
            },
            warning: {
              light: tokens.color.brand.warning.dark.value,
              dark: tokens.color.brand.warning.base.value,
            },
            'warning-light': {
              light: tokens.color.brand.warning.dark.value,
              dark: tokens.color.brand.warning.light.value,
            },
            'warning-dark': {
              light: tokens.color.brand.warning.dark.value,
              dark: tokens.color.brand.warning.dark.value,
            },
            ghost: {
              light: tokens.color.base.white.value,
              dark: tokens.color.base.black.value,
            },
            control: {
              // light: '#00ff00',
              // dark: '#ff0000'
            },
            focus: undefined,
            placeholder: {
              light: tokens.color.brand.default.dark.value,
              dark: tokens.color.brand.default.light.value,
            },
            selected: {
              dark: tokens.color.brand.primary.base.value,
              light: tokens.color.brand.primary.base.value,
            },
            text: {
              light: tokens.color.font.base.value,
              dark: tokens.color.font.default.value,
            },
            // icon: ColorType,
            white: tokens.color.base.gray[10].value,
            // 'accent-1': ColorType,
            // 'accent-2': ColorType,
            // 'accent-3': ColorType,
            // 'accent-4': ColorType,
            background: {
              light: tokens.color.background.inverse.value,
              dark: tokens.color.background.base.value,
            },
            'background-back': {
              light: tokens.color.background.inverse.value,
              dark: tokens.color.background.base.value,
            },
            // 'background-contrast': ColorType,
            // 'background-front': ColorType,
            // 'neutral-1': ColorType,
            // 'neutral-2': ColorType,
            // 'neutral-3': ColorType,
            // 'neutral-4': ColorType,
            'dark-1': tokens.color.base.gray[1000].value,
            'dark-2': tokens.color.base.gray[900].value,
            'dark-3': tokens.color.base.gray[800].value,
            'dark-4': tokens.color.base.gray[700].value,
            'dark-5': tokens.color.base.gray[650].value,
            'dark-6': tokens.color.base.gray[600].value,
            'light-1': tokens.color.base.gray[50].value,
            'light-2': tokens.color.base.gray[200].value,
            'light-3': tokens.color.base.gray[300].value,
            'light-4': tokens.color.base.gray[400].value,
            'light-5': tokens.color.base.gray[500].value,
            'light-6': tokens.color.base.gray[550].value,
            'status-critical': tokens.color.brand.danger.base.value,
            'status-error': tokens.color.brand.danger.base.value,
            'status-warning': tokens.color.brand.warning.base.value,
            'status-ok': tokens.color.brand.success.base.value,
            'status-unknown': tokens.color.base.gray[500].value,
            'status-disabled': tokens.color.base.gray[500].value,
            // 'graph-0': ColorType,
            // 'graph-1': ColorType,
            // 'graph-2': ColorType,
            // 'graph-3': ColorType,
            // 'graph-4': ColorType,
            // 'graph-5': ColorType,
          },
          control: {
            border: {
              // width: string,
              radius: '2px',
              // color: ColorType,
            },
            disabled: {
              opacity: 1,
            },
          },
        },
        tab: {
          active: {
            background: tokens.color.base.purple[600].value,
            color: tokens.color.font.default.value,
          },
          background: tokens.color.base.white.value,
          border: {
            // side: string,
            // size: string,
            color: tokens.color.brand.primary.base.value,
            active: {
              color: tokens.color.brand.primary.base.value,
            },
            // disabled: {
            //   color: ColorType,
            // },
            hover: {
              // color: ColorType,
              // extend: ExtendType,
            },
          },
          // color: tokens.color.brand.primary.base.value,
          // disabled: {
          //   color: ColorType,
          // },
          extend: css`
            border-radius: 24px;
            height: 48px;
            border: solid 2px ${tokens.color.base.purple[600].value};
            font-size: 18px;
            font-weight: bold;
          `,
          hover: {
            background: tokens.color.base.purple[200].value,
            color: tokens.color.brand.primary.base.value,
            extend: css`
              color: ${tokens.color.brand.primary.base.value};
            `,
          },
          // margin: MarginType,
          pad: {
            horizontal: tokens.size.padding.xl.value,
            vertical: tokens.size.padding.m.value,
          },
        },
        tabs: {
          // background: BackgroundType,
          // extend: ExtendType,
          // gap: GapType,
          // header: {
          //   background: BackgroundType,
          //   border: {
          //     side: string,
          //     size: string,
          //     style: string,
          //     color: ColorType,
          //   },
          //   extend: ExtendType,
          // },
          // panel: {
          //   extend: ExtendType,
          // },
        },
        focus: {
          border: {
            color: tokens.color.brand.primary.base.value,
          },
          outline: {
            color: tokens.color.brand.primary.base.value,
            size: '1px',
          },
          shadow: {
            color: undefined,
            size: '1px',
          },
        },
        accordion: {
          panel: {
            border: undefined,
          },
          border: undefined,
          heading: {
            // level: string,
            margin: { vertical: '12px', horizontal: '6px' },
          },
          hover: {
            // color: ColorType, // deprecated
            heading: {
              // color: ColorType,
            },
          },
          // icons: {
          //   collapse: AngleCollapse,
          //   expand: AngleExpand,
          //   // color: ColorType,
          // },
        },
      }}
    >
      {element}
    </Grommet>
  )
}

export default grommetProvider
